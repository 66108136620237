.resku #admin-nav {
  height: 34px; }
  .resku #admin-nav .cs-nav {
    height: 34px; }
    .resku #admin-nav .cs-nav ul {
      margin: 0;
      list-style: none; }
      .resku #admin-nav .cs-nav ul li {
        display: inline-block;
        border: 0;
        margin-left: -4px;
        border-bottom: 1px solid #eaeaea; }
        .resku #admin-nav .cs-nav ul li a {
          color: #5ba0b4;
          display: inline-block;
          padding: 9px 7px;
          text-decoration: none;
          white-space: nowrap;
          padding-bottom: 6px;
          font-size: 14px; }
        .resku #admin-nav .cs-nav ul li a:hover {
          padding-bottom: 3px;
          border-bottom: 3px solid #d8ecf1; }
      .resku #admin-nav .cs-nav ul li.active a {
        font-weight: 700;
        border-bottom: 3px solid #5ba0b4;
        padding-bottom: 3px; }

.resku .main-content {
  margin-top: 0; }
  .resku .main-content > .row {
    margin-left: 0;
    margin-right: 0; }
